<template>
  <div class="box ">
    <div class="left_content">
      <div class="left_content_img">
        <img
          src="@/assets/images/distributionLogin/图标.png"
          alt=""
        />
      </div>
      <div class="left_content_text">
        <p>通信网服中心</p>
        <p>发货订单上传</p>
      </div>
      <el-card class="box-card">
        <div class="card_content">
          <img
            class="icon"
            src="@/assets/images/oderUpload/icon.png"
            alt=""
          />
          <p class="name">{{ userinfo.nickname }}</p>
          <div
            class="card_progress"
            v-if="userinfo.pid != 0"
          >
            <el-progress
              :percentage="progressVal"
              :show-text="false"
            ></el-progress>
            <span style="margin-left: 2px">L<i>{{ userinfo.level }}</i>
            </span>
            <span
              style="margin-left: 14px">{{ userinfo.Activated }}/{{ userinfo.noActive }}</span>
          </div>
          <button @click="myNickName()">上传订单</button>
        </div>
      </el-card>
      <el-table
        :header-cell-style="{
          fontSize: '14px',
          color: '#888888',
          fontWeight: '400',
          width: '100%',
          background:'#C2D8FF',
          border:'1px solid #C2D8FF',
        }"
        :header-row-style="{
          lineHeight:'10px',
          }"
        :data="UserList"
      >
        <el-table-column
          prop="data"
          label=""
          width="100"
        >
          <template>
            <img
              src="@/assets/images/oderUpload/icon.png"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="昵称"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="money"
          label="单张分佣"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="上传订单"
          width="100"
        >
          <template slot-scope="scope">
            <button
              class="table_btn"
              @click="updataOder(scope.row)"
            >
              上传订单
            </button>
          </template>
        </el-table-column>
        <el-table-column
          prop="count"
          label="总销量"
          width="100"
        >
          <template slot-scope="scope">
            <p @click="updataOder(scope.row)">
              {{scope.row.count}}张
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right_content">
      <el-card class="right_card">
        <p class="up_name">
          上传<span>{{ nowNickName }}</span>的发货订单
        </p>
        <div class="show_uploadLog">
          <button @click="showUploadLog">上传记录</button></div>
        <p class="up_title">拖拽表格文件到这里</p>
        <el-upload
          ref="upload"
          class="upload-demo"
          :data="paramData"
          accept=".xls,.xlsx"
          drag
          action="https://connect.ydbimg.com/api/v1.Distribution/import"
          :on-success="handlerSuccess"
          :auto-upload="false"
          :limit="1"
          multiple
        >
          <i class="upload_i"><img
              src="@/assets/images/oderUpload/下载.png"
              alt=""
            /></i>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em>
            <p class="up_tip">仅支持xls、xlsx文件，大小不超过150kb</p>
          </div>
        </el-upload>
        <button
          class="up_btn"
          @click="ok"
        >确定</button>
      </el-card>
      <!-- <button>下载文件模板</button> -->
      <div
        class="download_btn"
        @click="dowLnoad()"
        @mousedown="down"
        @mouseup="up"
      >
        <img
          src="@/assets/images/oderUpload/下载蓝.png"
          alt=""
          v-show="showImg"
        />
        <img
          src="@/assets/images/oderUpload/下载白.png"
          alt=""
          v-show="!showImg"
        />
        <!-- <img src="" alt=""> -->
        <div class="download_text">
          <p>下载文件模板</p>
          <p>请参考文件模板按标注填写</p>
        </div>
      </div>
      <el-card
        class="success"
        v-if="success"
      >
        <img
          src="@/assets/images/oderUpload/success.png"
          alt=""
        />
        上传成功
      </el-card>
    </div>
    
    <el-dialog title="上传记录" :visible.sync="dialogTableVisible">
      <el-table :data="RecordList">
        <el-table-column property="createtime_text" label="上传日期" ></el-table-column>
        <el-table-column property="userjoin.nickname" label="上传人" width="200"></el-table-column>
        <el-table-column property="address" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination style="margin-top: 20px;"
        background
        layout="prev, pager, next"
        @next-click="next()"
        :total="1000">
      </el-pagination> -->
    </el-dialog>
  </div>
</template>

<script>
import distubritionTwoApi from '@/untils/distubritionTwo.js'
import Api from '@/untils/api.js'



const zoom = () => {
  let x = window.innerWidth / 1920 // 固定值
  let y = window.innerHeight / 1080 // 固定值
  let domBody = document.getElementsByTagName('body')[0]
  domBody.style.transform = `scale(${x}, ${y})`
  domBody.style.webkitTransform = `scale(${x}, ${y})` /* for Chrome || Safari */
  domBody.style.msTransform = `scale(${x}, ${y})` /* for Firefox */
  domBody.style.mozTransform = `scale(${x}, ${y})` /* for IE */
  domBody.style.oTransform = `scale(${x}, ${y})` /* for Opera */
}
zoom()
window.addEventListener('resize', zoom, false)
export default {
  data () {
    return {
      progressVal: 0,
      success: false,
      nowNickName: '',
      showImg: true,
      userinfo: {},
      UserList: [],
      owner_id: '',
      option:{},
      paramData: {
        user_id: '',
        // user_id:1,
        // owner_id:1
        owner_id: ''
      },
      isShowUpload:false,
      RecordList:[],
      dialogTableVisible: false,



    }
  },
  created () {

  },
  mounted(){
    var userInfos = sessionStorage.getItem('userInfo')
    this.option = JSON.parse(userInfos)
    this.paramData.user_id =  this.option.id
    this.getUserInfo()
    this.queryUserList()
    this.paramData.owner_id = this.paramData.user_id
    // this.paramData.user_id = this.paramData.user_id
  },
  methods: {
    // next(){
    //   console.log('这是下一页');
    // },
    // 显示上传记录
    showUploadLog(){
      let data ={
        user_id:this.paramData.user_id
      }
      distubritionTwoApi.downRecord({data}).then(res=>{
        if(res.data.code == 200){
          this.RecordList = res.data.data.data.data
          this.dialogTableVisible = true
        }
      })
      
    },
    handleClick(row){
      window.location.href = "https://connect.yuna6.com"+row.file_url

    },
    // 下载模板
    dowLnoad () {
      window.location.href = "https://connect.ydbimg.com/download_file/demo.xlsx"
    },
    // 点击确定按钮
    ok () {
      this.$refs.upload.submit()
    },
    // 列表上传订单按钮
    updataOder (row) {
      this.nowNickName = row.nickname
      this.paramData.owner_id = row.id
    },
    // 下载文件模板按钮样式
    down () {
      this.showImg = false
    },
    up () {
      this.showImg = true
    },
    myNickName () {
      this.nowNickName = this.userinfo.nickname
      this.paramData.owner_id = this.paramData.user_id
    },
    // 上传文件成功后的回调
    handlerSuccess (res, file) {
      if (res.code == 200) {
        this.success = true
        setTimeout(() => {
          this.success = false
        }, 2000)
      } else {
        this.$message(res.msg);
      }

    },
    // 获取本人用户信息
    getUserInfo () {
      let data = {
        mobile:  this.option.mobile,
      }

      Api.getUserInfo({ data }).then((res) => {
        if (res.data.code == 200) {
          this.userinfo = res.data.data.data
          this.nowNickName = this.userinfo.nickname
          this.progressVal = this.userinfo.noActivated / this.userinfo.Activated * 100
        }
      })
    },

    // 获取列表用户信息
    queryUserList () {
      let data = {
        mobile:  this.option.mobile,
        page: 1
      }
      Api.getQueryUserList({ data }).then((res) => {
        if (res.data.code == 200) {
          this.UserList = res.data.data.data
        }

      })
    },

  }
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 1920px;
  height: 1080px;
  transform-origin: 10 0 0; /*指定缩放的基本点*/
  -moz-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
  overflow: hidden;
}
.box {
  height: 1080px;
  width: 100%;
  background: url("~@/assets/images/oderUpload/背景.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  position: relative; 
}
.UploadLog{
  width: 400px;
  height: 400px;
  border: 1px solid #ccc;
  position: absolute;
  top: 15%;
  right: 50%;
  background-color: #fff;
}

.left_content {
  width: 350px;
  /* height: 702px; */
  height: 100%;
  padding-top: 10px;
  /* margin-left: 0%; */
}
.left_content .left_content_img img {
  margin-top: 30px;
  margin-left: 29px;
  width: 62px;
  margin-bottom: 8px;
}
.left_content .left_content_text p:nth-child(1) {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  float: left;
  margin-right: 29px;
}
.left_content .left_content_text p:nth-child(2) {
  font-size: 14px;
  line-height: 28px;
  color: #666;
}
.box-card {
  width: 350px;
  height: 88px;
  margin: 25px 0;
}
.box-card .card_content .icon {
  float: left;
  width: 56px;
  /* display: flex;
    justify-content: space-between; */
}
.box-card .card_content .name {
  width: 50px;
  text-align: center;
  font-size: 14px;
  color: #1e6fff;
  font-weight: 500;
  line-height: 55px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.box-card .card_content .card_progress {
  float: left;
  margin-top: 23px;
}
.box-card .card_content .card_progress span {
  color: #1e6fff;
  line-height: 15px;
  font-size: 12px;
}
.box-card .card_content .card_progress .el-progress {
  width: 120px;
}
.box-card button {
  float: right;
  color: #1d6cfc;
  font-weight: 700;
  border: 1px solid #1d6cfc;
  border-radius: 4px;
  width: 58px;
  height: 22px;
  margin-right: 5px;
  margin-top: 20px;
  line-height: 22px;
  cursor: pointer;
  background-color: #ffffff;
}
.box-card button:hover {
  background-color: #1d6cfc;
  color: white;
}
.el-table__header {
  border: 1px solid #c2d8ff;
}
.el-table__row td {
  font-size: 12px;
  height: 54px;
}
.el-table__body tr:hover > td {
  background-color: #c9ddff !important;
  cursor: pointer;
}
.cell {
  text-align: center;
}
.el-table__row:hover {
  background-color: #c9ddff;
}
.el-table__row .table_btn {
  background-color: transparent;
  color: #1d6cfc;
  cursor: pointer;
  width: 58px;
  height: 22px;
  border-radius: 4px;
}
.el-table__row .table_btn:hover {
  background-color: #1d6cfc;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  width: 58px;
  height: 22px;
  border-radius: 4px;
}
.el-table__row .table_btn:active {
  background-color: #1d6cfc;
  color: #ffffff;
  font-weight: 700;
  width: 58px;
  height: 22px;
  border-radius: 4px;
}
.right_content {
  margin-left: 60px;
  margin-top: 130px;
  text-align: center;
  margin-right: 30%;
}
.right_card {
  width: 682px;
  height: 425px;
  position: relative;
}
.show_uploadLog{
  position: absolute;
  top: 10px;
  right: 10px;
  
}
.show_uploadLog button{
  cursor: pointer;
  border: 1px solid #1d6cfc;
  background-color: #fff;
  color: #1d6cfc;
  width: 50px;
  height: 20px;
  line-height: 20px;
  border-radius: 5px;
}
.show_uploadLog button:hover{
 background-color: #1d6cfc;
 color: #fff;
}
.right_card .up_name {
  font-size: 14px;
  font-weight: 700;
}
.right_card .up_name span {
  margin: 0 7px;
  color: #1e6fff;
}
.right_card .up_title {
  font-size: 24px;
  font-weight: 700;
  color: #1e6fff;
  margin-top: 26px;
  margin-bottom: 20px;
}
.right_card .upload_i img {
  margin-top: 15px;
  margin-bottom: 20px;
}
.right_card .up_tip {
  font-size: 12px;
  line-height: 28px;
  color: #999999;
  margin-top: 15px;
}
.el-upload-dragger .el-icon-upload {
  /* width: 170px;
    height: 115px; */
  color: #e0f0fd;
}
.right_card .up_btn {
  margin-top: 35px;
  width: 237px;
  height: 36px;
  background-color: #1e6fff;
  color: #ffffff;
  border-radius: 30px;
  font-size: 14px;
  cursor: pointer;
}
.right_content .download_btn {
  border: 1px solid #1e6fff;
  border-radius: 6px;
  width: 186px;
  height: 57px;
  margin: 0 auto;
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.right_content .download_btn:hover {
  border: 2px solid #1e6fff;
}
.right_content .download_btn:active {
  background-color: #1e6fff;
  color: #ffffff;
  /* content: url('~@/assets/images/oderUpload/下载白.png'); */
}

.right_content .download_btn img {
  width: 26px;
  height: 25px;
  /* float: left;
    margin: auto 0; */
}
/* .right_content .download_btn img:not([src]){
    content: url('~@/assets/images/oderUpload/下载蓝.png');
} */
.right_content .download_btn .download_text {
  margin-left: 7px;
}
.right_content .download_btn .download_text p:nth-child(1) {
  font-size: 16px;
}
.right_content .download_btn .download_text p:nth-child(2) {
  font-size: 10px;
  color: #999999;
  margin-top: 3px;
}
.success {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 18px;
}
.el-dialog{
  /* margin: auto; */
  left: -10%;
  width: 500px;
}

</style>